<template>
  <div id="settings-wrapper">
    <common-trs-btn
      class="mr-3 expand-icon"
      elevation="1"
      fab
      small
      @click="true ? setDrawer(!drawer) : $emit('input', !value)"
    >
       <v-icon
        v-if="!drawer || value"
        class="icon-right-nav"
        color="primary"
      />
      <v-icon
        v-else
        class="icon-left-nav"
        color="primary"
      />
    </common-trs-btn>

    <v-card
      v-if="false"
      id="settings"
      class="py-2 px-4"
      color="rgba(0, 0, 0, .3)"
      dark
      flat
      link
      min-width="100"
      style="position: fixed; top: 115px; right: -35px; border-radius: 8px;"
    >
      <v-icon large>
        mdi-cog-outline
      </v-icon>
    </v-card>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      activator="#settings"
      bottom
      content-class="v-settings"
      left
      nudge-left="8"
      offset-x
      origin="top right"
      transition="scale-transition"
    >
      <v-card
        class="text-center mb-0"
        width="300"
      >
        <v-card-text>
          <strong class="mb-3 d-inline-block">THEME COLORS</strong>

          <v-row
            align="center"
            no-gutters
          >
            <v-col
              v-for="(color, key) in themeColors"
              :key="key"
              cols="4"
            >
              <v-menu
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <common-trs-btn
                    small
                    block
                    tile
                    :color="color"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ key }}
                  </common-trs-btn>
                </template>
                <v-color-picker
                  v-model="themeColors[key]"
                  show-swatches
                  swatches-max-height="300px"
                />
              </v-menu>
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <strong class="mb-3 d-inline-block">SIDEBAR BACKGROUND</strong>

          <v-item-group v-model="barColor">
            <v-item
              v-for="scrim in scrims"
              :key="scrim"
              :value="scrim"
              class="mx-1"
            >
              <template v-slot="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="scrim"
                  class="v-settings__item"
                  size="24"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4 secondary" />

          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              Dark Mode
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <common-input-switch
                v-model="$vuetify.theme.dark"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              Sidebar Mini
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <common-input-switch
                v-model="internalValue"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              Sidebar Image
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <common-input-switch
                v-model="showImg"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <strong class="mb-3 d-inline-block">IMAGES</strong>

          <v-item-group
            v-model="barImage"
            class="d-flex justify-space-between mb-3"
          >
            <v-item
              v-for="image in images"
              :key="image"
              :value="image"
              class="mx-1"
            >
              <template v-slot="{ active, toggle }">
                <v-sheet
                  :class="active && 'v-settings__item--active'"
                  class="d-inline-block v-settings__item"
                  @click="toggle"
                >
                  <v-img
                    :src="image"
                    height="100"
                    width="50"
                  />
                </v-sheet>
              </template>
            </v-item>
          </v-item-group>

          <common-trs-btn
            block
            class="mb-3"
            color="success"
            default
            @click="reset"
          >
            Reset
          </common-trs-btn>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'
  import { mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreSettings',

    mixins: [Proxyable],

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      images: [
        'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
        'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-2.jpg',
        'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-3.jpg',
        'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-4.jpg',
      ],
      menu: false,
      scrims: [
        'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
        'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
        'rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)',
      ],
    }),

    computed: {
      showImg: {
        get () {
          return this.$store.getters['theme/theme'].showImg
        },
        set (val) {
          if (!val) {
            this.barImage = ''
          } else {
            this.barImage = this.images[0]
          }
        },
      },
      themeColors: {
        get () {
          return this.$store.getters['theme/theme'].themeColors
        },
        set (val) {
          this.$store.commit('theme/SET_THEME_COLORS', val)
        },
      },
      barImage: {
        get () {
          return this.$store.getters['theme/theme'].barImage
        },
        set (val) {
          this.$store.commit('theme/SET_BAR_IMAGE', val)
        },
      },
      drawer: {
        get () {
          return this.$store.state.app.drawer
        },
        set (val) {
          this.$store.commit('app/SET_DRAWER', val)
        },
      },
      barColor: {
        get () {
          return this.$store.getters['theme/theme'].barColor
        },
        set (val) {
          this.$store.commit('theme/SET_SCRIM', val)
        },
      },
    },

    watch: {
      themeColors: {
        handler (val) {
          this.$store.commit('theme/SET_THEME_COLORS', val)
          for (const c in val) {
            this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'][c] = val[c]
          }
        },
        immediate: true,
        deep: true,
      },
    },

    created () {
    },

    methods: {
      ...mapMutations('theme', {
        setBarImage: 'SET_BAR_IMAGE',
      }),
      ...mapMutations('app', {
        setDrawer: 'SET_DRAWER',
      }),
      reset () {
        this.$store.commit('theme/RESET')
      },
    },
  }
</script>

<style lang="sass">
  .v-settings
    .v-item-group > *
      cursor: pointer

    &__item
      border-width: 3px
      border-style: solid
      border-color: transparent !important

      &--active
        border-color: #00cae3 !important
</style>

<style lang="scss" scoped>
  .expand-icon {
    position: fixed;
    z-index: -0!important;
    width: 32px!important;
    height: 32px!important;
    background-color: #fff!important;
    left: -5px;
    top: 26px
  }
</style>
